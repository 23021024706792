<script>
  import { format } from "date-fns";
  import { fade } from "svelte/transition";
  export let skuDetails = {
    description: "",
    inserted_at: "",
    updated_at: "",
  };
  export let closePopup;
  function formatDate(dateString) {
    return format(new Date(dateString), "yyyy-MM-dd HH:mm:ss");
  }
</script>

<div class="modal">
  <div class="modal-content" transition:fade>
    <h2>{skuDetails.description} SKU</h2>
    <table class="info-table">
      <tr>
        <th>Attribute</th>
        <th>Value</th>
      </tr>
      <tr>
        <td>Inserted At:</td>
        <td class="date-cell">{formatDate(skuDetails.inserted_at) || ""}</td>
      </tr>
      <tr>
        <td>Updated At:</td>
        <td class="date-cell">{formatDate(skuDetails.updated_at) || ""}</td>
      </tr>
    </table>
    <div class="modal-buttons">
      <button class="cancel-button" on:click={() => closePopup()}>Cancel</button
      >
    </div>
  </div>
</div>

<style>
  .modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }

  .modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    max-width: 400px;
    width: 100%;
    text-align: center;
  }

  .modal-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }

  button {
    padding: 10px 20px;
    margin-left: 10px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }

  .cancel-button {
    background: #ff4d4d;
    color: #fff;
  }

  h2 {
    margin: 0;
    padding: 10px;
    font-size: 20px;
  }

  button:hover {
    background: #0056b3;
  }
  .info-table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ccc;
    margin-top: 10px;
  }

  .info-table th,
  .info-table td {
    padding: 8px;
    text-align: left;
    border: 1px solid #ccc;
  }

  /* Style for table headers */
  .info-table th {
    background-color: #f2f2f2;
  }
</style>
