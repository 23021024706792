<script>
  import Swal from "sweetalert2";
  import { onMount } from "svelte";
  import Admin from "./admin.svelte";
  let token = "";
  let verify = false;
  onMount(
    async () => {
      // Ask the user to input the token using window.prompt
      token = window.prompt("Please enter your token:");
      // Call the API to verify the token
      let host = "";
      if (process.env.config.environment === "development") {
        host = process.env.config.localServerUrl;
      }
      const response = await fetch(
        `${host}/api/v2/admin/products/verify?token=${token}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        verify = data.verify;
      } else {
        // Handle error
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Failed to verify token!",
        });
      }
    },
    verify,
    token
  );
</script>

{#if verify}
  <Admin {token} />
{:else}
  <br />
  <h2>403 You are not Authorized to Access this page</h2>
{/if}

<style>
  h2 {
    text-align: center;
  }
</style>
