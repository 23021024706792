<script>
  import { quill } from "@tadashi/svelte-editor-quill/src/quill";

  export let options = {};
  export let data = "";
  let className = "";
  export { className as class };

  options = {
    theme: "snow",
    ...options,
  };

  function onchange(event) {
    data = event.detail.html;
  }
</script>

<div class={className}>
  <div use:quill={options} on:text-change={onchange}>
    <div
      class="ql-editor"
      data-gramm="false"
      contenteditable="true"
      data-placeholder="Type something..."
    >
      {@html data}
    </div>
  </div>
</div>
