<script>
  export let token = "";
  import Swal from "sweetalert2";
  import { format } from "date-fns";
  import { onMount } from "svelte";
  import PreviewProduct from "./PreviewProduct.svelte";
  import UpdateProduct from "./UpdateProduct.svelte";
  import PriceList from "./PriceList.svelte";
  import Loader from "../../../components/Loader.svelte";
  import { Circle3 } from "svelte-loading-spinners";

  let products = [];
  let productToPreview = null;
  let productToUpdate = null;
  let productDetailsId = null;
  let messageToShow = null;
  let isLoading = false;
  let language = localStorage.getItem("enrol-app-locale") || "en";

  onMount(async () => {
    fetchProducts(language);
  });
  function formatDate(dateString) {
    return format(new Date(dateString), "yyyy-MM-dd HH:mm:ss");
  }
  async function fetchProducts(language) {
    let host = "";
    if (process.env.config.environment === "development") {
      host = process.env.config.localServerUrl;
    }
    const response = await fetch(
      `${host}/api/v2/admin/products?language=${language}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authentication: `Basic ${token}`,
        },
      }
    );
    if (response.ok) {
      const data = await response.json();
      products = data.products;
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Failed to fetch products!",
      });
    }
  }
  function showUpdate(product) {
    productToUpdate = product;
    const updateProductModal = new UpdateProduct({
      target: document.body,
      props: {
        token,
        product: productToUpdate,
        onClose: () => closeUpdate(),
        isOpen: true,
      },
    });
    updateProductModal;
  }
  function closeUpdate() {
    products = [];
    fetchProducts(language);
  }
  function openPreview(id, message) {
    productToPreview = id;
    messageToShow = message;
  }
  function closePreview() {
    productToPreview = null;
  }
  function showDetails(product) {
    productDetailsId = product.id;
  }
  function closeDetails() {
    productDetailsId = null;
  }

  function changeLocale() {
    fetchProducts(language);
  }

  function startLoading() {
    isLoading = true;
  }

  function stopLoading() {
    isLoading = false;
  }

  async function toggleProductApi(product, value) {
    startLoading();
    const formData = {
      attributes: {
        deleted: value,
      },
    };

    let host = "";
    if (process.env.config.environment == "development") {
      host = process.env.config.localServerUrl;
    }
    const response = await fetch(
      host + "/api/v2/admin/products/" + product.id,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authentication: `Basic ${token}`,
        },
        body: JSON.stringify(formData),
      }
    );

    stopLoading();
    if (response.status == 404) {
      const errorData = await response.json(); // Parse the response JSON
      const message = errorData.error;
      Swal.fire({
        icon: "error",
        title: "Oops...",
        html: message,
      });
    } else if (response.status == 200) {
      Swal.fire({
        icon: "success",
        title: "Success!",
        html: `Product is now ${value ? "deleted" : "shown"}!`,
      });
      fetchProducts(language);
    }
  }

  async function toggleProduct(product, value) {
    const confirmed = await Swal.fire({
      title: "Are you sure?",
      text: `Do you want to ${
        product.deleted
          ? `show ${product.title} again`
          : `hide ${product.title}`
      } ?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    });

    if (confirmed.isConfirmed) {
      toggleProductApi(product, value);
    }
  }

  let showPriceTable = null; // Initialize showPriceTable with null

  function togglePriceTable(productId) {
    if (showPriceTable === productId) {
      showPriceTable = null; // Close the table if it's open
    } else {
      showPriceTable = productId; // Open the table for the selected product
    }
  }

  async function handlePriceSuccess(event) {
    await fetchProducts(language);
  }
</script>

<svelte:head>
  <link href="//cdn.quilljs.com/1.3.6/quill.snow.css" rel="stylesheet" />
</svelte:head>
<Loader visible={isLoading}>
  <div class="processing">Please Wait ...</div>
</Loader>
<div class="main">
  <h3>Products list</h3>
  <div class="container-main">
    <h2>Please Select Products List By Language</h2>
    <div class="dropdown">
      <select bind:value={language} on:change={changeLocale}>
        <option value="en">🇬🇧 English</option>
        <option value="fr">🇫🇷 French</option>
        <option value="de">🇩🇪 Deutsh</option>
        <option value="it">🇮🇹 Italian</option>
        <option value="nl">🇳🇱 Netherlands</option>
      </select>
      <div class="chevron">
        <span>&#9662;</span>
      </div>
    </div>
    <br />
    <br />
    <table class="responsive-table">
      <thead>
        <tr>
          <th>Id</th>
          <th>Title</th>
          <th>Description</th>
          <th>Thumbnail</th>
          <th>Success Page</th>
          <th>Confirmation Email</th>
          <th>Error Email</th>
          <th>Actions</th>
        </tr>
      </thead>
      {#if products && products.length}
        <tbody>
          {#each products as product}
            <tr
              style=" {product.deleted
                ? 'background-color: #ecc9c9; opacity: 0.5;'
                : ''}"
            >
              <td>{product.id || ""}</td>
              <td>{product.title || ""}</td>
              <td class="thankyou-message-cell">
                {product.description || ""}
                {#if product.description}
                  <div
                    on:keydown={(event) => {
                      if (event.key === "Enter" || event.key === "Space") {
                        openPreview(product.id, product.description);
                      }
                    }}
                    on:click={() =>
                      openPreview(product.id, product.description)}
                  >
                    <span
                      class="eye-icon-preview-thankyou-message"
                      title="Preview Product Description"
                    >
                      👁️
                    </span>
                  </div>
                {/if}
              </td>
              <td
                >{#if product.thumbnail}
                  <img
                    class="thumbnail"
                    alt={product.title}
                    src={product.thumbnail}
                  />
                {/if}
              </td>
              <td class="thankyou-message-cell">
                {product.thank_you_message || ""}
                {#if product.thank_you_message}
                  <div
                    on:keydown={(event) => {
                      if (event.key === "Enter" || event.key === "Space") {
                        openPreview(product.id, product.thank_you_message);
                      }
                    }}
                    on:click={() =>
                      openPreview(product.id, product.thank_you_message)}
                  >
                    <span
                      class="eye-icon-preview-thankyou-message"
                      title="Preview success page"
                    >
                      👁️
                    </span>
                  </div>
                {/if}
              </td>
              <td class="thankyou-message-cell">
                {product.confirmation_email || ""}
                {#if product.confirmation_email}
                  <div
                    on:keydown={(event) => {
                      if (event.key === "Enter" || event.key === "Space") {
                        openPreview(product.id, product.confirmation_email);
                      }
                    }}
                    on:click={() =>
                      openPreview(product.id, product.confirmation_email)}
                  >
                    <span
                      class="eye-icon-preview-thankyou-message"
                      title="Preview Confirmation Email"
                    >
                      👁️
                    </span>
                  </div>
                {/if}
              </td>
              <td class="thankyou-message-cell">
                {product.error_email || ""}
                {#if product.error_email}
                  <div
                    on:keydown={(event) => {
                      if (event.key === "Enter" || event.key === "Space") {
                        openPreview(product.id, product.error_email);
                      }
                    }}
                    on:click={() =>
                      openPreview(product.id, product.error_email)}
                  >
                    <span
                      class="eye-icon-preview-thankyou-message"
                      title="Preview Error Email"
                    >
                      👁️
                    </span>
                  </div>
                {/if}
              </td>
              <td>
                <div style="display: flex">
                  <button
                    class="btn btn-sm btn--primary"
                    on:click={() => showDetails(product)}>Details</button
                  >
                  <button
                    style="margin-left: -2px;"
                    class="btn btn-sm btn--secondary"
                    on:click={() => showUpdate(product)}>Update</button
                  >
                  {#if product.deleted}
                    <button
                      class="btn btn-sm btn--danger margin-button"
                      on:click={() => toggleProduct(product, false)}
                      >Show</button
                    >
                  {:else}
                    <button
                      class="btn btn-sm btn--danger margin-button"
                      on:click={() => toggleProduct(product, true)}
                      >Delete</button
                    >
                  {/if}
                  <button
                    class="btn btn-sm btn--info margin-button"
                    on:click={() => togglePriceTable(product.id)}
                    on:keydown={(event) => {
                      if (event.key === "Enter" || event.key === "Space") {
                        togglePriceTable(product.id);
                      }
                    }}
                  >
                    Prices List
                  </button>
                </div>
              </td>
            </tr>
            <div
              class="modal-message"
              class:show={productToPreview === product.id}
            >
              <PreviewProduct
                message={messageToShow}
                onClose={() => closePreview()}
              />
            </div>
            <div
              class="modal-message"
              class:show={productDetailsId === product.id}
            >
              <div class="modal-content">
                <h2 style="text-align: center">{product.title} Details</h2>
                <br />
                <table class="info-table">
                  <tr>
                    <th>Attribute</th>
                    <th>Value</th>
                  </tr>
                  <tr>
                    <td>Inserted At:</td>
                    <td class="date-cell"
                      >{formatDate(product.inserted_at) || ""}</td
                    >
                  </tr>
                  <tr>
                    <td>Updated At:</td>
                    <td class="date-cell"
                      >{formatDate(product.updated_at) || ""}</td
                    >
                  </tr>
                </table>
                <br />
                <br />
                <button class="close-button" on:click={closeDetails}
                  >Close</button
                >
              </div>
            </div>
            {#if showPriceTable === product.id}
              <PriceList
                onStart={() => startLoading()}
                onStop={() => stopLoading()}
                on:onSuccess={handlePriceSuccess}
                {product}
                {token}
              />
            {/if}
          {/each}
        </tbody>
      {:else}
        <tbody>
          <tr
            ><td style="text-align: center" colspan="8">
              <div class="circle3-spinner">
                <Circle3 size="60" unit="px" duration="1s" />
              </div>
              Loading Rows...
            </td></tr
          >
        </tbody>
      {/if}
    </table>
  </div>
</div>

<style>
  /* Table styles */
  table {
    width: 100%;
    border-collapse: collapse;
  }

  th,
  td {
    border: 1px solid #ccc;
    padding: 8px;
    max-width: 400px;
    word-wrap: break-word;
  }
  th {
    background-color: #f2f2f2;
  }
  .eye-icon-preview-thankyou-message {
    float: right;
    cursor: pointer;
  }
  .thankyou-message-cell {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  /* Add CSS styles for the modal */
  .modal-message {
    position: fixed;
    top: 50px;
    z-index: 9999;
    left: 50px;
    right: 50px;
    bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s;
    white-space: break-spaces;
  }
  .show {
    opacity: 1;
    pointer-events: auto;
    z-index: 1;
  }
  h3 {
    text-align: center;
    margin-bottom: 35px;
  }
  .dropdown {
    position: relative;
    display: inline-block;
    width: 100%;
  }

  select {
    appearance: none;
    -webkit-appearance: none;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    cursor: pointer;
  }

  .chevron {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
  }

  .chevron span {
    font-size: 12px;
  }

  .circle3-spinner {
    margin-left: 45%;
  }

  .main {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  table.responsive-table {
    /* width: max-content; */
    border-collapse: collapse;
    /* margin: 0 auto; */
    position: relative;
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;
  }
  .modal-content {
    background-color: white;
    padding: 40px;
    border-radius: 5px;
    padding-left: 60px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    width: 90%;
    max-height: 90%;
    overflow: auto;
  }

  .close-button {
    position: inherit;
    float: right;
    bottom: 10px;
    right: 10px;
    margin: 0;
    padding: 5px 10px;
    background-color: var(--secondary);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .info-table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ccc;
    margin-top: 10px;
  }

  .info-table th,
  .info-table td {
    padding: 8px;
    text-align: left;
    border: 1px solid #ccc;
  }

  /* Style for table headers */
  .info-table th {
    background-color: #f2f2f2;
  }

  .container-main {
    flex-grow: 1;
    overflow-x: auto; /* Enable horizontal scrolling */
    max-width: 100%;
    padding: 20px;
    box-sizing: border-box;
    min-width: 100px; /* Set a minimum width to prevent the content from getting too narrow */
  }

  @media screen and (max-width: 768px) {
    .container-main {
      padding: 10px;
    }
  }

  .btn--danger {
    color: #fff;
    background-color: #d9534f;
    border-color: #d43f3a;
  }
  .btn-sm {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
  }

  .margin-button {
    margin-left: var(--baseline);
    transition: 0.4s;
  }

  .btn--info {
    cursor: pointer;
    background-color: #17a2b8;
    border-color: #066c7c;
    color: #fff;
  }

  .btn--info:hover {
    background-color: #0a6775;
  }

  .thumbnail {
    max-width: 200px;
    max-height: 150px;
    width: auto;
    height: auto;
  }
</style>
