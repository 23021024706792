<!-- routify:options bundle=true -->
<script>
  export let scoped;
  import Error from "../../components/Error.svelte";
  import Header from "../../components/Header.svelte";
  const { loading: parentLoading } = scoped;
  const loading = Promise.all([parentLoading]);
</script>

<Header title="Admin Portal" />

{#await loading}
  <!-- loading -->
{:then}
  <div>
    <slot scoped />
  </div>
{:catch error}
  <Error {error} />
{/await}
