<script>
  import Swal from "sweetalert2";
  import { createEventDispatcher } from "svelte";

  export let product, token, onStart, onStop;
  const dispatch = createEventDispatcher();

  const columnsCount = 8;
  let priceToUpdate = null;

  function openPriceUpdatePopup(price) {
    let priceId = price.id;
    let value = price.value;
    let language = price.language;
    let currency_code = price.currency_code;

    priceToUpdate = priceId;

    Swal.fire({
      title: `Update price: ${value} ${currency_code}`,
      html:
        '<p style="margin-top: 20px; color: #888;">If you proceed, a new price will be created in both Stripe and Paypal accounts, and the old price will be archived.</p>' +
        '<input type="number" id="swal-input1" class="swal2-input" placeholder="Enter the updated price">',
      showCancelButton: true,
      confirmButtonText: "Update",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        // Parse the input as an integer
        const updatedPrice = document.getElementById("swal-input1").value;
        const parsedPrice = parseFloat(updatedPrice);

        if (isNaN(parsedPrice)) {
          Swal.showValidationMessage("Please enter a valid integer.");
        } else {
          return updatePrice(priceToUpdate, parsedPrice, language);
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
      backdrop: "rgba(0, 0, 0, 0.8)",
      icon: "warning",
      customClass: {
        content: "swal-content-custom",
      },
    });
  }

  // Function to update the price using the API
  async function updatePrice(priceId, updatedPrice, language) {
    if (!/^[0-9]\d*$/.test(updatedPrice)) {
      Swal.fire({
        icon: "error",
        title: "Invalid Input",
        text: "Please enter a positive integer for the price.",
      });
      return;
    }
    onStart();

    const host =
      process.env.config.environment === "development"
        ? process.env.config.localServerUrl
        : "";
    const formData = { amount: updatedPrice, language: language };

    try {
      const response = await fetch(`${host}/api/v2/admin/prices/${priceId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authentication: `Basic ${token}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        // Price update was successful, so fetch products again
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Price updated successfully!",
        });
        dispatch("onSuccess", {});
      } else {
        // Handle error case - display Swal error alert
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Failed to update price!",
        });
      }
      onStop();
    } catch (error) {
      console.error("Error updating price:", error);
      // Handle the error
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Failed to update price!",
      });
      onStop();
    }
  }
</script>

<tr>
  <td colspan={columnsCount}>
    <table>
      <tr>
        <th>Price Id</th>
        <th>SKU</th>
        <th>Number of Payments</th>
        <th>Payment Frequency</th>
        <th>Price</th>
        <th>Currency</th>
        <th>Actions</th>
      </tr>
      {#each product.variants as variant}
        {#each variant.prices as price}
          <tr>
            <td>{price.id || ""}</td>
            <td>{price.sku || ""}</td>
            <td>{variant.number_of_payments || ""}</td>
            <td>{variant.payment_frequency || ""}</td>
            <td>{price.value || ""}</td>
            <td>{price.currency_code || ""}</td>
            <td>
              <button
                class="btn btn-sm btn--secondary"
                on:click={() => openPriceUpdatePopup(price)}
              >
                Update Price
              </button>
            </td>
          </tr>
        {/each}
      {/each}
    </table>
  </td>
</tr>

<style>
  /* Table styles */
  table {
    width: 100%;
    border-collapse: collapse;
  }

  th,
  td {
    border: 1px solid #ccc;
    padding: 8px;
    max-width: 300px;
    word-wrap: break-word;
  }
  th {
    background-color: #f2f2f2;
  }

  .btn-sm {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
  }
</style>
