<script>
  import { onMount } from "svelte";
  import { fade } from "svelte/transition";

  export let message, onClose;
  async function handleClickOutside(event) {
    if (!message) {
      return;
    }
    const modalContent = await document.querySelector(".modal-content");
    if (
      modalContent &&
      !modalContent.contains(event.target) &&
      !event.target.classList.contains("eye-icon-preview-thankyou-message")
    ) {
      onClose();
    }
  }
  // Add the click event listener when the component is mounted
  onMount(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  });
  function stopPropagation(event) {
    event.stopPropagation();
  }
</script>

<div
  class="modal-content"
  on:keydown={(event) => {
    if (event.key === "Enter" || event.key === "Space") {
      stopPropagation;
    }
  }}
  on:click={stopPropagation}
  transition:fade
>
  <h2 style="text-align: center">Preview</h2>
  <br />
  {@html message}
  <br />
  <br />
  <button class="close-button" on:click={onClose}>Close</button>
</div>

<style>
  .modal-content {
    background-color: white;
    padding: 40px;
    border-radius: 5px;
    padding-left: 60px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    width: 90%;
    max-height: 90%;
    overflow: auto;
  }
  .close-button {
    position: inherit;
    float: right;
    bottom: 10px;
    right: 10px;
    margin: 0;
    padding: 5px 10px;
    background-color: var(--secondary);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
</style>
