<script>
  export let token = "";
  import Swal from "sweetalert2";
  import { format } from "date-fns";
  import { onMount } from "svelte";
  import Loader from "../../../components/Loader.svelte";
  import { Circle3 } from "svelte-loading-spinners";
  import { LightPaginationNav } from "svelte-paginate";
  import UpdatePopup from "./UpdatePopup.svelte";
  import MoreDetailsPopup from "./MoreDetailsPopup.svelte";

  let isPopupOpen = false;
  let isMoreDetailsPopupOpen = false;
  let selectedSku = null; // To store the selected SKU details

  // Function to open the popup for updating
  function openUpdatePopup(skuDetails) {
    selectedSku = { ...skuDetails };
    isPopupOpen = true;
  }

  function openMoreDetailsPopup(skuDetails) {
    selectedSku = { ...skuDetails };
    isMoreDetailsPopupOpen = true;
  }

  function closePopup() {
    isPopupOpen = false;
  }

  function closeMoreDetailsPopup() {
    isMoreDetailsPopupOpen = false;
  }

  let isLoading = false;
  let pageSize = 10;
  let currentPage = 1;
  let skus_file_maker_products = [];
  let totalItems = 0;

  onMount(async () => {
    await fetchFileMakerProducts();
  });
  function formatDate(dateString) {
    return format(new Date(dateString), "yyyy-MM-dd HH:mm:ss");
  }
  async function fetchFileMakerProducts() {
    let host = "";
    if (process.env.config.environment === "development") {
      host = process.env.config.localServerUrl;
    }
    const response = await fetch(
      `${host}/api/v2/admin/file_maker_skus?page=${currentPage}&per_page=${pageSize}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authentication: `Basic ${token}`,
        },
      }
    );
    if (response.ok) {
      const data = await response.json();
      skus_file_maker_products = data.skus_file_maker_products;
      totalItems = data.total_items;
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Failed to fetch the SKUs file maker products!",
      });
    }
  }

  function startLoading() {
    isLoading = true;
  }

  function stopLoading() {
    isLoading = false;
  }

  function handlePageChange(newPage) {
    currentPage = newPage;
    fetchFileMakerProducts();
  }

  function fetchData() {
    fetchFileMakerProducts();
  }
</script>

<Loader visible={isLoading}>
  <div class="processing">Please Wait ...</div>
</Loader>

<div class="main">
  <h3>SKUs File Maker Products list</h3>
  <div class="container-main">
    <table class="responsive-table">
      <thead>
        <tr>
          <th>Id</th>
          <th>SKU</th>
          <th>Description</th>
          <th>Price</th>
          <th>Actions</th>
        </tr>
      </thead>
      {#if skus_file_maker_products && skus_file_maker_products.length}
        <tbody>
          {#each skus_file_maker_products as sku_file_maker}
            <tr>
              <td>{sku_file_maker.id || ""}</td>
              <td>{sku_file_maker.sku || ""}</td>
              <td>{sku_file_maker.description || ""}</td>
              <td>{sku_file_maker.price || ""}</td>
              <td>
                <div style="display: flex">
                  <button
                    style="margin-right: 10px;"
                    class="btn btn-sm btn--secondary"
                    on:click={() => openUpdatePopup(sku_file_maker)}
                  >
                    Update
                  </button>
                  <button
                    style="margin-left: -2px;"
                    class="btn btn-sm btn--primary"
                    on:click={() => openMoreDetailsPopup(sku_file_maker)}
                  >
                    Details
                  </button>
                </div>
              </td>
            </tr>
          {/each}
        </tbody>
      {:else}
        <tbody>
          <tr
            ><td style="text-align: center" colspan="6">
              <div class="circle3-spinner">
                <Circle3 size="60" unit="px" duration="1s" />
              </div>
              Loading Rows...
            </td></tr
          >
        </tbody>
      {/if}
    </table>
    {#if isPopupOpen}
      <UpdatePopup {token} skuDetails={selectedSku} {fetchData} {closePopup} />
    {/if}
    {#if isMoreDetailsPopupOpen}
      <MoreDetailsPopup
        skuDetails={selectedSku}
        closePopup={closeMoreDetailsPopup}
      />
    {/if}
    <LightPaginationNav
      {totalItems}
      {pageSize}
      {currentPage}
      limit={1}
      showStepOptions={true}
      on:setPage={(e) => handlePageChange(e.detail.page)}
    />
  </div>
</div>

<style>
  .container-main {
    flex-grow: 1;
    overflow-x: auto; /* Enable horizontal scrolling */
    max-width: 100%;
    padding: 20px;
    box-sizing: border-box;
    min-width: 100px; /* Set a minimum width to prevent the content from getting too narrow */
  }

  @media screen and (max-width: 768px) {
    .container-main {
      padding: 10px;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  th,
  td {
    border: 1px solid #ccc;
    padding: 8px;
    max-width: 400px;
    word-wrap: break-word;
  }
  th {
    background-color: #f2f2f2;
  }

  h3 {
    text-align: center;
    margin-bottom: 35px;
  }

  .circle3-spinner {
    margin-left: 45%;
  }

  .main {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  table.responsive-table {
    border-collapse: collapse;
    position: relative;
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;
  }

  .btn-sm {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
  }
</style>
