<script>
  export let token = "";
  import Swal from "sweetalert2";
  import { onMount } from "svelte";
  import Loader from "../../../components/Loader.svelte";
  import { Circle3 } from "svelte-loading-spinners";
  import { LightPaginationNav } from "svelte-paginate";
  import UpdatePopup from "./UpdatePopup.svelte";
  import MoreDetailsPopup from "./MoreDetailsPopup.svelte";
  import LanguagePreview from "./LanguagePreview.svelte";
  import el from "date-fns/locale/el";

  let isPopupOpen = false;
  let isMoreDetailsPopupOpen = false;
  let selectedLanguage = null;
  let emailsList = null;
  let emailsListTitle = null;
  let languageMaintenancePageToPreview = null;

  // Function to open the popup for updating
  function openUpdatePopup(languageDetails) {
    selectedLanguage = { ...languageDetails };
    isPopupOpen = true;
    const updateProductModal = new UpdatePopup({
      target: document.body,
      props: {
        token,
        languageDetails: selectedLanguage,
        closePopup: () => closePopup(),
        isPopupOpen,
        fetchData,
      },
    });
    updateProductModal;
  }

  function openMoreDetailsPopup(languageDetails) {
    selectedLanguage = { ...languageDetails };
    isMoreDetailsPopupOpen = true;
  }

  function closePopup() {
    isPopupOpen = false;
  }

  function closeMoreDetailsPopup() {
    isMoreDetailsPopupOpen = false;
  }

  function openlanguageMaintenancePageToPreview(title, name, content) {
    emailsListTitle = `Preview Language ${name.toUpperCase()}'s ${title} List`;
    console.log(content);
    languageMaintenancePageToPreview = content;
  }

  function openPreview(emails_list, title, name) {
    if (!emails_list || emails_list.length == 0) {
      return;
    }
    emailsListTitle = `Preview Language ${name.toUpperCase()}'s ${title} List`;
    emailsList = emails_list.split(",").map((word) => word.trim());
  }

  function closePreview() {
    emailsList = null;
    emailsListTitle = null;
    languageMaintenancePageToPreview = null;
  }

  let isLoading = false;
  let pageSize = 10;
  let currentPage = 1;
  let languages = [];
  let totalItems = 0;

  onMount(async () => {
    await fetchLanguages();
  });

  async function fetchLanguages() {
    let host = "";
    if (process.env.config.environment === "development") {
      host = process.env.config.localServerUrl;
    }
    const response = await fetch(
      `${host}/api/v2/admin/languages?page=${currentPage}&per_page=${pageSize}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authentication: `Basic ${token}`,
        },
      }
    );
    if (response.ok) {
      const data = await response.json();
      languages = data.languages;
      totalItems = data.total_items;
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Failed to fetch the languages!",
      });
    }
  }

  function startLoading() {
    isLoading = true;
  }

  function stopLoading() {
    isLoading = false;
  }

  function handlePageChange(newPage) {
    currentPage = newPage;
    fetchLanguages();
  }

  function fetchData() {
    fetchLanguages();
  }
</script>

<svelte:head>
  <link href="//cdn.quilljs.com/1.3.6/quill.snow.css" rel="stylesheet" />
</svelte:head>

<Loader visible={isLoading}>
  <div class="processing">Please Wait ...</div>
</Loader>

<div class="main">
  <h3>Languages list</h3>
  <div class="container-main">
    <table class="responsive-table">
      <thead>
        <tr>
          <th>Id</th>
          <th>name</th>
          <th>Reply to</th>
          <th>Email BCC</th>
          <th>Maintenance page</th>
          <th>Enabled</th>
          <th>Actions</th>
        </tr>
      </thead>
      {#if languages && languages.length}
        <tbody>
          {#each languages as language}
            <tr>
              <td>{language.id || ""}</td>
              <td>{language.name || ""}</td>
              <td class="tighten-td"
                >{language.reply_to || ""}
                {#if language.reply_to}
                  <div
                    on:keydown={(event) => {
                      if (event.key === "Enter" || event.key === "Space") {
                        openPreview(
                          language.reply_to,
                          "Reply to",
                          language.name
                        );
                      }
                    }}
                    on:click={() =>
                      openPreview(language.reply_to, "Reply to", language.name)}
                  >
                    <span
                      class="eye-icon-preview"
                      title="Preview Reply to list"
                    >
                      👁️
                    </span>
                  </div>
                {/if}
              </td>
              <td class="tighten-td"
                >{language.email_bcc || ""}
                {#if language.email_bcc}
                  <div
                    on:keydown={(event) => {
                      if (event.key === "Enter" || event.key === "Space") {
                        openPreview(
                          language.email_bcc,
                          "Email BCC",
                          language.name
                        );
                      }
                    }}
                    on:click={() =>
                      openPreview(
                        language.email_bcc,
                        "Email BCC",
                        language.name
                      )}
                  >
                    <span
                      class="eye-icon-preview"
                      title="Preview Email BCC list"
                    >
                      👁️
                    </span>
                  </div>
                {/if}</td
              >
              <td class="tighten-td"
                >{language.maintenance_page || ""}
                {#if language.maintenance_page}
                  <div
                    on:keydown={(event) => {
                      if (event.key === "Enter" || event.key === "Space") {
                        openlanguageMaintenancePageToPreview(
                          "Maintenance Page",
                          language.name,
                          language.maintenance_page
                        );
                      }
                    }}
                    on:click={() =>
                      openlanguageMaintenancePageToPreview(
                        "Maintenance Page",
                        language.name,
                        language.maintenance_page
                      )}
                  >
                    <span
                      class="eye-icon-preview"
                      title="Preview Email BCC list"
                    >
                      👁️
                    </span>
                  </div>
                {/if}</td
              >
              <td class="tighten-td">
                {#if language.enabled_maintenance_page}
                  <b style="color:green">Enabled</b>
                {:else}
                  <b style="color:red">Disabled</b>
                {/if}
              </td>
              <td>
                <div style="display: flex">
                  <button
                    style="margin-right: 10px;"
                    class="btn btn-sm btn--secondary"
                    on:click={() => openUpdatePopup(language)}
                  >
                    Update
                  </button>
                  <button
                    style="margin-left: -2px;"
                    class="btn btn-sm btn--primary"
                    on:click={() => openMoreDetailsPopup(language)}
                  >
                    Details
                  </button>
                </div>
              </td>
            </tr>
          {/each}
        </tbody>
      {:else}
        <tbody>
          <tr
            ><td style="text-align: center" colspan="6">
              <div class="circle3-spinner">
                <Circle3 size="60" unit="px" duration="1s" />
              </div>
              Loading Rows...
            </td></tr
          >
        </tbody>
      {/if}
    </table>
    {#if isMoreDetailsPopupOpen}
      <MoreDetailsPopup
        languageDetails={selectedLanguage}
        closePopup={closeMoreDetailsPopup}
      />
    {/if}
    {#if emailsList && emailsList.length > 0}
      <LanguagePreview
        title={emailsListTitle}
        list={emailsList}
        onClose={() => closePreview()}
      />
    {/if}
    {#if languageMaintenancePageToPreview}
      <LanguagePreview
        title={emailsListTitle}
        content={languageMaintenancePageToPreview}
        onClose={() => closePreview()}
      />
    {/if}
    <LightPaginationNav
      {totalItems}
      {pageSize}
      {currentPage}
      limit={1}
      showStepOptions={true}
      on:setPage={(e) => handlePageChange(e.detail.page)}
    />
  </div>
</div>

<style>
  .container-main {
    flex-grow: 1;
    overflow-x: auto; /* Enable horizontal scrolling */
    max-width: 100%;
    padding: 20px;
    box-sizing: border-box;
    min-width: 100px; /* Set a minimum width to prevent the content from getting too narrow */
  }

  @media screen and (max-width: 768px) {
    .container-main {
      padding: 10px;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  th,
  td {
    border: 1px solid #ccc;
    padding: 8px;
    max-width: 400px;
    word-wrap: break-word;
  }
  th {
    background-color: #f2f2f2;
  }

  h3 {
    text-align: center;
    margin-bottom: 35px;
  }

  .circle3-spinner {
    margin-left: 45%;
  }

  .main {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  table.responsive-table {
    border-collapse: collapse;
    position: relative;
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;
  }

  .btn-sm {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
  }

  .tighten-td {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .eye-icon-preview {
    float: right;
    cursor: pointer;
  }
</style>
