<script>
  import { onMount } from "svelte";
  import { fade } from "svelte/transition";

  export let title, list, content, onClose;
  console.log(content);
  async function handleClickOutside(event) {
    if (!list) {
      return;
    }
    if (!content) {
      return;
    }
    const modalContent = await document.querySelector(".modal-content");
    if (
      modalContent &&
      !modalContent.contains(event.target) &&
      !event.target.classList.contains("eye-icon-preview")
    ) {
      onClose();
    }
  }
  // Add the click event listener when the component is mounted
  onMount(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  });
  function stopPropagation(event) {
    event.stopPropagation();
  }
</script>

<div class="modal">
  <div
    class="modal-content"
    on:keydown={(event) => {
      if (event.key === "Enter" || event.key === "Space") {
        stopPropagation;
      }
    }}
    on:click={stopPropagation}
    transition:fade
  >
    <h2 style="text-align: center">{title}</h2>
    <br />
    <ul>
      {#if content}
        {@html content}
      {:else}
        {#each list as item (item)}
          <li>{item}</li>
        {/each}
      {/if}
    </ul>
    <br />
    <br />
    <button class="close-button" on:click={onClose}>Close</button>
  </div>
</div>

<style>
  .modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }

  .modal-content {
    background: #fff;
    padding: 20px;
    padding-left: 45px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    max-width: 400px;
    width: 100%;
    max-height: 300px;
    overflow-y: scroll;
  }

  .close-button {
    position: inherit;
    float: right;
    bottom: 10px;
    right: 10px;
    margin: 0;
    padding: 5px 10px;
    background-color: var(--secondary);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
</style>
