<script>
  import Swal from "sweetalert2";
  import Editor from "../../../components/Editor.svelte";
  import Loader from "../../../components/Loader.svelte";
  import { fade } from "svelte/transition";
  import Slider from "../../../components/Slider.svelte";
  import { convertImageToBase64, resizeBase64Image } from "../../../utils";

  export let product,
    token,
    onClose,
    isOpen = false;
  let thankYouMessage = product.thank_you_message;
  let confirmationEmail = product.confirmation_email;
  let title = product.title;
  let description = product.description;
  let errorEmail = product.error_email;
  let thumbnail = product.thumbnail;
  let isLoading = false;
  let orderID = "{orderID}";
  let price_value = "{price_value}";
  let shopify1 = "{Shopify1}, {shopify1}";
  let firstName = "{firstName}";
  let lastName = "{lastName}";
  let loginButton = "{login_button}";
  let Mode = "Text";

  function closeModal() {
    isOpen = false;
    onClose();
  }
  function startLoading() {
    isLoading = true;
  }

  function stopLoading() {
    isLoading = false;
  }

  let options = {
    modules: {
      toolbar: [
        ["bold", "italic", "underline", "strike", "link", "video"], // toggled buttons
        ["blockquote", "code-block"],

        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }], // superscript/subscript
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ direction: "rtl" }], // text direction

        [{ size: ["small", false, "large", "huge"] }], // custom dropdown
        [{ header: [1, 2, 3, 4, 5, 6, false] }],

        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ font: [] }],
        [{ align: [] }],

        ["clean"],
      ],
    },
    placeholder: "Type something...",
    theme: "snow",
  };

  async function confirmAndSubmit() {
    const confirmed = await Swal.fire({
      title: "Are you sure?",
      text: "Do you want to submit this form?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    });

    if (confirmed.isConfirmed) {
      handleSubmit();
    }
  }

  function isEmpty(string) {
    const aux = document.createElement("div");
    aux.innerHTML = string;
    return aux.innerText.trim() === "";
  }

  async function handleSubmit() {
    startLoading();
    if (isEmpty(title)) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "The title of the product shouldn't be empty!",
      });
      stopLoading();
      return;
    }
    if (isEmpty(thankYouMessage)) {
      thankYouMessage = null;
    }
    if (isEmpty(confirmationEmail)) {
      confirmationEmail = null;
    }
    if (isEmpty(description)) {
      description = null;
    }
    if (isEmpty(errorEmail)) {
      errorEmail = null;
    }

    if (isEmpty(thumbnail)) {
      thumbnail = null;
    }

    const formData = {
      attributes: {
        title: title,
        thank_you_message: thankYouMessage,
        confirmation_email: confirmationEmail,
        description: description,
        error_email: errorEmail,
      },
    };

    let host = "";
    if (process.env.config.environment == "development") {
      host = process.env.config.localServerUrl;
    }
    convertImageToBase64(thumbnail)
      .then((base64) => {
        if (!base64) {
          return null;
        }
        return resizeBase64Image(base64, 300, 250);
      })
      .then((thumbnailBase64) => {
        formData.attributes.thumbnail = thumbnailBase64;
        return fetch(host + "/api/v2/admin/products/" + product.id, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authentication: `Basic ${token}`,
          },
          body: JSON.stringify(formData),
        });
      })
      .then(async (response) => {
        if (response.status == 404) {
          const errorData = await response.json(); // Parse the response JSON
          const message = errorData.error;
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: message,
          });
        } else if (response.status == 200) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            html: `Product updated successfully!`,
          });
        }
        stopLoading();
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        stopLoading();
      });
  }

  function changeImage(event) {
    const file = event.target.files[0];

    if (file) {
      thumbnail = URL.createObjectURL(file);
    }
  }

  function triggerFileInput() {
    const fileInput = document.getElementById("fileInput");
    if (fileInput) {
      fileInput.click();
    }
  }

  function revertToOriginal() {
    thumbnail = product.thumbnail;
  }
  function stopPropagation(event) {
    event.stopPropagation();
  }

  function onChangeDescription(event) {
    description = event.detail.html;
  }

  function onChangeSuccessPage(event) {
    thankYouMessage = event.detail.html;
  }

  function onChangeConfirmationEmail(event) {
    confirmationEmail = event.detail.html;
  }

  function onChangeErrorEmail(event) {
    errorEmail = event.detail.html;
  }
</script>

{#if isOpen}
  <Loader visible={isLoading}>
    <div class="processing">Please Wait ...</div>
  </Loader>
  <div class="show modal-message">
    <div
      class="modal-content"
      on:keydown={(event) => {
        if (event.key === "Enter" || event.key === "Space") {
          stopPropagation;
        }
      }}
      on:click={stopPropagation}
      transition:fade
    >
      <div
        style="float: right; cursor: pointer"
        on:keydown={(event) => {
          if (event.key === "Enter" || event.key === "Space") {
            closeModal();
          }
        }}
        on:click={() => closeModal()}
      >
        ✖
      </div>
      <h1 style="text-align: center">{product.name}</h1>
      <p class="size-of-notice">
        <strong>
          * {orderID}, {price_value}, {firstName}, {lastName}, {loginButton}, {shopify1}
          existing in the text, will be replace by its correct value.
        </strong>
      </p>
      <div>
        <div style="position: relative; float: right; white-space: normal;">
          <Slider bind:value={Mode} leftValue="Text" rightValue="HTML">
            <span slot="left">Text</span>
            <span slot="right">HTML</span>
          </Slider>
        </div>
        <div id="thumbnail-header">
          <h3>Thumbnail</h3>
          <div class="thumbnail-header-style">
            <img class="thumbnail" alt={product.name} src={thumbnail} />

            <input
              type="file"
              accept="image/*"
              id="fileInput"
              on:change={changeImage}
              style="display: none;"
            />

            <button on:click={triggerFileInput} class="upload-button"
              >Upload New Image</button
            >
            {#if thumbnail != product.thumbnail}
              <button on:click={revertToOriginal} class="revert-button"
                >Revert to Original</button
              >
            {/if}
          </div>
        </div>
        <div id="title-header">
          <h3>Title *</h3>
          <input
            id="productName"
            name="productName"
            type="text"
            class="input"
            required
            bind:value={title}
          />
        </div>
        <div id="description-header">
          <h3>Description</h3>
          {#if Mode == "Text"}
            <Editor
              {options}
              on:text-change={onChangeDescription}
              bind:data={description}
            />
          {:else if Mode == "HTML"}
            <textarea bind:value={description} />
          {/if}
        </div>
        <div id="success-page-header">
          <h3>Success Page</h3>
          {#if Mode == "Text"}
            <Editor
              {options}
              on:text-change={onChangeSuccessPage}
              bind:data={thankYouMessage}
            />
          {:else if Mode == "HTML"}
            <textarea bind:value={thankYouMessage} />
          {/if}
        </div>
        <div id="confirmation-email-header">
          <h3>Confirmation Email</h3>
          {#if Mode == "Text"}
            <Editor
              {options}
              on:text-change={onChangeConfirmationEmail}
              bind:data={confirmationEmail}
            />
          {:else if Mode == "HTML"}
            <textarea bind:value={confirmationEmail} />
          {/if}
        </div>
        <div id="error-email-header">
          <h3>Error Email</h3>
          {#if Mode == "Text"}
            <Editor
              {options}
              on:text-change={onChangeErrorEmail}
              bind:data={errorEmail}
            />
          {:else if Mode == "HTML"}
            <textarea bind:value={errorEmail} />
          {/if}
        </div>
        <button class="submit-button" on:click={() => confirmAndSubmit()}
          >Submit</button
        >
      </div>
      <button class="close-button" on:click={() => closeModal()}>Close</button>
    </div>
  </div>
{/if}

<style>
  @import "https://cdn.quilljs.com/1.3.6/quill.snow.css";
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    max-width: 90%;
    max-height: 90%;
    overflow: auto;
  }
  .close-button {
    position: inherit;
    float: right;
    bottom: 10px;
    right: 10px;
    margin: 0;
    padding: 5px 10px;
    background-color: var(--primary);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  .submit-button {
    position: inherit;
    float: right;
    bottom: 10px;
    right: 10px;
    margin: 0;
    padding: 5px 10px;
    background-color: var(--secondary);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 19px;
    margin-left: 10px;
  }
  .processing {
    font-size: 52pt;
    font-weight: bold;
    color: white;
    text-shadow: 0 0 5px black;
  }
  .input {
    display: block;
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }

  .modal-message {
    position: fixed;
    top: 50px;
    z-index: 1;
    left: 50px;
    right: 50px;
    bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s;
    white-space: break-spaces;
  }
  .show {
    opacity: 1;
    pointer-events: auto;
  }
  .size-of-notice {
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 16px;
    white-space: initial;
  }

  textarea {
    width: 100%;
    max-width: 100%;
    height: 250px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  textarea:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }

  .thumbnail {
    max-width: 200px;
    max-height: 150px;
    width: auto;
    height: auto;
    margin-top: 15px;
  }

  .upload-button {
    background-color: #0074d9;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
  }

  .upload-button:hover {
    background-color: #0056b3;
  }

  .thumbnail-header-style {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .revert-button {
    background-color: #e74c3c;
    color: #fff;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 15px;
    margin-top: 5px;
  }

  .revert-button:hover {
    background-color: #c0392b;
  }
</style>
