<!-- UpdatePopup.svelte -->
<script>
  import Swal from "sweetalert2";

  export let skuDetails = {
    id: null,
    sku: "",
    description: "",
    price: null,
  };
  export let fetchData;
  export let token;
  export let closePopup;

  async function save() {
    const result = await Swal.fire({
      title: "Confirm Update",
      text: `Are you sure you want to update this SKU: ${skuDetails.description}?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes, update",
      cancelButtonText: "Cancel",
    });

    if (result.isConfirmed) {
      let host = "";
      if (process.env.config.environment === "development") {
        host = process.env.config.localServerUrl;
      }
      const response = await fetch(
        `${host}/api/v2/admin/file_maker_skus/${skuDetails.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authentication: `Basic ${token}`,
          },
          body: JSON.stringify({
            attributes: {
              sku: skuDetails.sku,
              description: skuDetails.description,
              price: skuDetails.price,
            },
          }),
        }
      );

      if (response.ok) {
        await Swal.fire("Success", "SKU updated successfully", "success");
        closePopup();
        fetchData();
      } else {
        await Swal.fire("Error", "Failed to update SKU", "error");
        console.error("Update failed");
      }
    }
  }
</script>

<div class="modal">
  <div class="modal-content">
    <h2>Update SKU</h2>
    <label for="sku">SKU</label>
    <input type="text" id="sku" bind:value={skuDetails.sku} placeholder="SKU" />
    <label for="description">Description</label>
    <input
      type="text"
      id="description"
      bind:value={skuDetails.description}
      placeholder="Description"
    />
    <label for="price">Price</label>
    <input
      type="number"
      id="price"
      bind:value={skuDetails.price}
      placeholder="Price"
    />

    <div class="modal-buttons">
      <button class="save-button" on:click={save}>Save</button>
      <button class="cancel-button" on:click={() => closePopup()}>Cancel</button
      >
    </div>
  </div>
</div>

<style>
  .modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }

  .modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    max-width: 400px;
    width: 100%;
    text-align: center;
  }

  label {
    display: block;
    text-align: left;
    margin-top: 10px;
  }

  input {
    display: block;
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }

  .modal-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }

  button {
    padding: 10px 20px;
    margin-left: 10px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }

  .save-button {
    background: #007bff;
    color: #fff;
  }

  .cancel-button {
    background: #ff4d4d;
    color: #fff;
  }

  h2 {
    margin: 0;
    padding: 10px;
    font-size: 20px;
  }

  button:hover {
    background: #0056b3;
  }
</style>
