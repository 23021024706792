<script>
  import Swal from "sweetalert2";
  import Tags from "svelte-tags-input";
  import Editor from "../../../components/Editor.svelte";
  import Slider from "../../../components/Slider.svelte";
  import Loader from "../../../components/Loader.svelte";
  import { fade } from "svelte/transition";

  export let isPopupOpen = false;
  let isLoading = false;
  export let languageDetails = {
    id: null,
    name: "",
    reply_to: "",
    email_bcc: null,
    maintenance_page: null,
    enabled_maintenance_page: false,
  };
  export let fetchData;
  export let token;
  export let closePopup;
  let Mode = "Text";
  let options = {
    modules: {
      toolbar: [
        ["bold", "italic", "underline", "strike", "link", "video"], // toggled buttons
        ["blockquote", "code-block"],

        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }], // superscript/subscript
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ direction: "rtl" }], // text direction

        [{ size: ["small", false, "large", "huge"] }], // custom dropdown
        [{ header: [1, 2, 3, 4, 5, 6, false] }],

        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ font: [] }],
        [{ align: [] }],

        ["clean"],
      ],
    },
    placeholder: "Type something...",
    theme: "snow",
  };

  function onChangeMaintenacePageValue(event) {
    maintenancePage = event.detail.html;
  }

  function stringToArray(inputString) {
    if (!inputString) {
      return [];
    }
    const wordsArray = inputString.split(",");
    const trimmedWordsArray = wordsArray.map((word) => word.trim());
    return trimmedWordsArray;
  }

  function isEmpty(string) {
    const aux = document.createElement("div");
    aux.innerHTML = string;
    return aux.innerText.trim() === "";
  }

  function isEmail(input) {
    // Regular expression to validate email addresses
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    return emailRegex.test(input);
  }

  function filterEmails(emails) {
    // Filter the list to keep only valid email addresses
    const validEmails = emails.filter((item) => isEmail(item));
    return validEmails;
  }

  let replyToTags = stringToArray(languageDetails.reply_to);
  $: {
    if (replyToTags.length) {
      replyToTags = filterEmails(replyToTags);
    }
  }

  let emailBCCTags = stringToArray(languageDetails.email_bcc);
  $: {
    if (emailBCCTags.length) {
      emailBCCTags = filterEmails(emailBCCTags);
    }
  }

  let maintenancePage = languageDetails.maintenance_page;
  let enabled = languageDetails.enabled_maintenance_page;

  async function save() {
    const result = await Swal.fire({
      title: "Confirm Update",
      text: `Are you sure you want to update the: ${languageDetails.name.toUpperCase()} language?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes, update",
      cancelButtonText: "Cancel",
    });

    if (result.isConfirmed) {
      let host = "";
      if (process.env.config.environment === "development") {
        host = process.env.config.localServerUrl;
      }
      if (isEmpty(maintenancePage)) {
        maintenancePage = null;
      }
      const response = await fetch(
        `${host}/api/v2/admin/languages/${languageDetails.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authentication: `Basic ${token}`,
          },
          body: JSON.stringify({
            attributes: {
              reply_to: replyToTags.join(","),
              email_bcc: emailBCCTags.join(","),
              maintenance_page: maintenancePage,
              enabled_maintenance_page: enabled,
            },
          }),
        }
      );

      if (response.ok) {
        await Swal.fire(
          "Success",
          "Language data updated successfully",
          "success"
        );
        closePopup();
        fetchData();
      } else {
        await Swal.fire("Error", "Failed to update the language data", "error");
        console.error("Update failed");
      }
    }
  }

  function closeModal() {
    isPopupOpen = false;
    closePopup();
  }

  const handleChange = (event) => {
    enabled = event.target.checked;
  };

  function stopPropagation(event) {
    event.stopPropagation();
  }
</script>

{#if isPopupOpen}
  <Loader visible={isLoading}>
    <div class="processing">Please Wait ...</div>
  </Loader>
  <div class="show modal-message">
    <div
      class="modal-content"
      on:keydown={(event) => {
        if (event.key === "Enter" || event.key === "Space") {
          stopPropagation;
        }
      }}
      on:click={stopPropagation}
      transition:fade
    >
      <div
        style="float: right; cursor: pointer"
        on:keydown={(event) => {
          if (event.key === "Enter" || event.key === "Space") {
            closeModal();
          }
        }}
        on:click={() => closeModal()}
      >
        ✖
      </div>

      <h2 style="text-align: center">
        Update {languageDetails.name.toUpperCase()} Language
      </h2>
      <ul class="notes-align">
        <li>Press "Enter" when you add a new email.</li>
        <li>The bellow inputs accept emails only.</li>
        <li>
          You can paste or drop a list of emails that are separated with a ",".
        </li>
      </ul>
      <h3 for="reply_to"><b>Reply to</b></h3>
      <Tags
        allowPaste={true}
        allowDrop={true}
        splitWith={","}
        placeholder={"Add Reply to list"}
        bind:tags={replyToTags}
      />
      <h3 for="email_bcc"><b>Email BCC</b></h3>
      <Tags
        allowPaste={true}
        allowDrop={true}
        splitWith={","}
        placeholder={"Add Email BCC list"}
        bind:tags={emailBCCTags}
      />

      <h3 for="maintenance_page"><b>Maintenance page</b></h3>
      <br />
      <div style="position: relative; float: left; white-space: normal;">
        <Slider
          id="maintenance_page"
          bind:value={Mode}
          leftValue="Text"
          rightValue="HTML"
        >
          <span slot="left">Text</span>
          <span slot="right">HTML</span>
        </Slider>
      </div>
      <br /><br />
      <input
        style="display:none"
        type="checkbox"
        bind:checked={enabled}
        id="enabled"
        name="enabled"
        on:change={handleChange}
      />
      <label class="label_item" for="enabled"
        ><h3>Show message to users</h3>
        <div class="warning-icon">
          <span style="color: #ff6347;"
            >⚠️ Caution: Enabling this option will make it visible to users.</span
          >
        </div>
      </label>

      {#if Mode == "Text"}
        <Editor
          {options}
          on:text-change={onChangeMaintenacePageValue}
          bind:data={maintenancePage}
        />
      {:else if Mode == "HTML"}
        <textarea bind:value={maintenancePage} />
      {/if}
      <div class="modal-buttons">
        <button class="save-button" on:click={save}>Save</button>
        <button class="cancel-button" on:click={() => closeModal()}
          >Cancel</button
        >
      </div>
    </div>
  </div>
{/if}

<style>
  @import "https://cdn.quilljs.com/1.3.6/quill.snow.css";
  .modal-message {
    position: fixed;
    top: 50px;
    z-index: 1;
    left: 50px;
    right: 50px;
    bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s;
    white-space: break-spaces;
  }
  .show {
    opacity: 1;
    pointer-events: auto;
  }

  textarea {
    width: 100%;
    max-width: 100%;
    height: 250px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  textarea:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }

  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    max-width: 90%;
    max-height: 90%;
    overflow: auto;
  }

  label {
    display: block;
    text-align: left;
    margin-top: 10px;
  }

  .modal-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }

  button {
    padding: 10px 20px;
    margin-left: 10px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }

  .processing {
    font-size: 52pt;
    font-weight: bold;
    color: white;
    text-shadow: 0 0 5px black;
  }

  .save-button {
    background: #007bff;
    color: #fff;
  }

  .cancel-button {
    background: #ff4d4d;
    color: #fff;
  }

  h2 {
    margin: 0;
    padding: 10px;
    font-size: 20px;
  }

  button:hover {
    background: #0056b3;
  }

  .notes-align {
    padding-left: 20px;
    text-align: initial;
  }

  label {
    display: flex;
    align-items: center;
    flex: 1;
    color: var(--primary);
    font-weight: bold;
    margin: 0;
  }

  label::before {
    content: "☐";
    font-size: 1.5rem;
    margin: 0 1rem;
  }

  input:checked + label {
    opacity: 1;
    color: var(--primary);
  }

  input:checked + label::before {
    content: "☑";
    color: var(--primary);
  }

  textarea {
    width: 100%;
    max-width: 100%;
    height: 250px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  textarea:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }

  .warning-icon {
    display: flex;
    align-items: center;
  }

  .warning-icon span {
    color: red;
    font-style: italic;
  }
</style>
